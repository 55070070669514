import React from 'react';
import './App.css';
import { NavLink, Switch, Route } from 'react-router-dom';
import Gallery from "react-photo-gallery";
import { photos } from "./components/photos";
import { SRLWrapper } from 'simple-react-lightbox'

const App = () => (
  <div className='app'>
    <h1>Monster Book</h1>
    <Navigation />
    <Main />
  </div>
);

const Navigation = () => (
  <nav>
    <ul>
      <li><NavLink exact activeClassName="current" to='/'>Home</NavLink></li>
      <li><NavLink exact activeClassName="current" to='/about'>About</NavLink></li>
      <li><NavLink exact activeClassName="current" to='/contact'>Contact</NavLink></li>
      <li><NavLink exact activeClassName="current" to='/photogallery'>Sneak Peek</NavLink></li>
      <li><NavLink exact activeClassName="current" to='/rulesbook'>Rules Book</NavLink></li>
    </ul>
  </nav>
);

const Main = () => (
 <div className="main">
    <Switch>
      <Route exact path='/' component={Home}></Route>
      <Route exact path='/about' component={About}></Route>
      <Route exact path='/contact' component={Contact}></Route>
      <Route exact path='/photogallery' component={BasicRows}></Route>
      <Route exact path='/rulesbook' component={RulesBook}></Route>
    </Switch>
  </div>
);

const Home = () => (
  <div className='home'>
    <h1>Welcome to the Monster Book website!</h1>
    <p>This website will contain info on the Monster Book Card Game. You can check out some cards, learn more about us, and even read the rules book for yourself!</p>
  </div>
);

const About = () => (
  <div className='about'>
    <h1>What is Monster Book??</h1>
    <p>Monster Book is a card game contained all in one, neat box. Up to 7 players will summon monsters, cast spells, and, most importantly, get lots, and lots of candy. Yes, real candy!</p>
  </div>
);

const Contact = () => (
  <div className='contact'>
    <h1>Contact Us!</h1>
    <p>For any rules questions, please contact us at the offical rules question email: monsterbookref@gmail.com</p>
  </div>
);

const BasicRows = () => (
  <SRLWrapper>
    <div className="photogallery">
      <h1>A peek into the Monster Book...</h1>
      <Gallery photos={photos} />
    </div>
  </SRLWrapper>
);

const RulesBook = () => (
  <div className="rulesbook">
    <iframe src="Monster Book Rules Book.pdf" width="100%" height="900px" />
  </div>
);

export default App;
