import headless from "./mbimages/The Headless.png"
import mothersupreme from "./mbimages/Mother Supreme.png"

export const photos = [
    {
      src: "https://i.imgur.com/1USLo7I.png",
      width: 3,
      height: 4
    },
    {
      src: "https://i.imgur.com/iE3UarU.png",
      width: 3,
      height: 4
    },
    {
      src: "https://i.imgur.com/JwtY07j.png",
      width: 3,
      height: 4
    },
    {
      src: "https://i.imgur.com/t0UWES3.png",
      width: 3,
      height: 4
    },
    {
      src: "https://i.imgur.com/oj95SWg.png",
      width: 3,
      height: 4
    },
    {
      src: "https://i.imgur.com/fmC0e3A.png",
      width: 3,
      height: 4
    },
    {
      src: "https://i.imgur.com/9r8rIp4.png",
      width: 3,
      height: 4
    },
    {
      src: "https://i.imgur.com/LwUqN5B.png",
      width: 3,
      height: 4
    },
    {
      src: "https://i.imgur.com/tIS8CXx.png",
      width: 3,
      height: 4
    },
    {
      src: "https://i.imgur.com/NxKBkSo.png",
      width: 3,
      height: 4
    },
    {
      src: "https://i.imgur.com/g6ZEOXr.png",
      width: 3,
      height: 4
    },
    {
      src: "https://i.imgur.com/ZP3et7X.png",
      width: 3,
      height: 4
    },
    {
      src: "https://i.imgur.com/EvTKLnF.png",
      width: 3,
      height: 4
    },
    {
      src: "https://i.imgur.com/XMBSQi6.png",
      width: 3,
      height: 4
    },
    {
      src: "https://i.imgur.com/3Pl8jxV.png",
      width: 3,
      height: 4
    },
    {
      src: "https://i.imgur.com/pCjPHAX.png",
      width: 3,
      height: 4
    }
  ];
